.App {
  text-align: center;
}

.email {
  position: absolute;
  left: -5000px;
}

.background {
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../public/images/blog-background.webp");
}

.container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  margin: 1rem;
}

body,
textarea,
p,
div,
button {
  font-family: "Lato", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: white;
}

a {
  text-decoration: none;
  color: rgb(220, 70, 68);
}

.responseText {
  white-space: pre-wrap; /* CSS property to preserve whitespace */
  /* min-height: 150px; */
  max-height: 40vh;
  overflow-y: scroll;
  width: 100%;
  /* max-width: 960px; */
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  font-size: 20px;
}

.responseText {
  color: white;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.panel {
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 80vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.prompt textarea {
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 1rem;
  font-size: 18px;
  margin-bottom: 1rem;
  box-sizing: border-box;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.prompt textarea::-webkit-scrollbar,
.responseText::-webkit-scrollbar,
.historyPage .panel::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.prompt textarea,
.responseText,
.historyPage .panel {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.prompt textarea {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

.prompt textarea:focus {
  outline: none;
  background-color: rgba(0, 0, 0, 0.8);
}

textarea.empty {
  cursor: text;
}

textarea.has-content {
  cursor: default;
}

button {
  font-size: 20px;
  background-color: black;
  border: 2px solid black;
  color: white;
  font-weight: 700;
  border-radius: 5px;
  min-width: 100px;
  padding: 5px 20px;
  transition: 200ms all ease-in-out;
}

button[disabled] {
  opacity: 0.7;
  cursor: not-allowed;
}

button:hover {
  background-color: rgb(220, 70, 68);
  border-color: rgb(220, 70, 68);
  color: black;
}

.icon-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.25rem;
  border-radius: 5px;
}

.icon-bar {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

.icon-bar i {
  margin: 0.25rem 1rem;
  font-size: 20px;
}

.icon-bar i:hover {
  color: rgb(220, 70, 68);
}

.fade-in {
  animation: fadeIn 1s;
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
