.historyPage .content {
  width: 100%;
}

.historyPage .panel {
  height: calc(100vh - 250px);
  margin-top: -50px;
  display: block;
  padding-bottom: 2rem;
  overflow-y: scroll;
}

.historyPage .no-blogs {
  margin: 0 auto;
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
}

.historyPage .no-blogs p {
  font-size: 20px;
}

.historyPage h1 {
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
  margin-top: 100px;
}

.historyItem {
  color: white;
  white-space: pre-wrap;
}

.historyPage .navigation {
  width: 100%;
  margin: 2rem auto;
  position: fixed;
  bottom: 0;
  display: block;
  background-color: rgba(220, 70, 68, 0.8);
}

.historyPage .navigation i {
  font-size: 24px;
  color: white;
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 50%;
  transition: all 300ms ease;
}

.historyPage .navigation i:hover {
  font-size: 32px;
  padding: 0 0.5rem;
}

.historyPage .navigation .fa-forward,
.historyPage .navigation .fa-backward {
  margin-left: 2rem;
  margin-right: 2rem;
}

.historyPage .navigation .icons .disabled {
  opacity: 0.3;
  pointer-events: none;
}
